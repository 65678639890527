import { assign } from './object-assign';
import { CustomEvent } from './custom-event';
import { find, findIndex } from './array-find';

if (typeof(Object.assign) !== 'function')
    Object.assign = assign;

if (typeof(window.CustomEvent) !== 'function') {
    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
} 

if (typeof(Array.prototype.find) !== 'function')
    Array.prototype.find = find;

if (typeof(Array.prototype.findIndex) !== 'function')
    Array.prototype.findIndex = findIndex;