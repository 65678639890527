export function findIndex(predicate, thisArg) {
    ensureArguments(this, predicate);
    return findIndexImp(this, predicate, thisArg);
}

export function find(predicate, thisArg) {
    ensureArguments(this, predicate);
    const idx = findIndexImp(this, predicate, thisArg);
    return idx >= 0 ? this[idx] : undefined;
}

function ensureArguments(self, predicate) {
    if (self == null)
        throw new TypeError('"this" is null or undefined');
    if (typeof(predicate) !== 'function')
        throw new TypeError('"predicate" must be a function');
}

function findIndexImp(self, predicate, thisArg) {
    const len = self.length >>> 0;
    let i = -1;

    while(++i < len) {
        if (predicate.call(thisArg, self[i], i, self))
            return i;
    }

    return -1;
}
